.cheque-print-btn{
    text-align: end;
   
}

.cheque-print-btn p{
    /* width: fit-content; */
    background-color: #4A00A8;
    border-radius: 0.5rem;
    text-align: center;
    height: 2rem;
}

.cheque-date-date p{
    background-color:#BEBEBE;
    border: solid rgb(139, 139, 139);
    border-radius: 0.3125rem;
    height: 2.125rem;
    width: 6.9375rem;
    /* opacity: 0.75; */
}

.check-detail-btn{
    background: #373737;
    border-radius: 0.125rem;
    color: #FFF;
    width: fit-content;
    padding: 0.375rem 0.9375rem;
}
.cheque-input{
    background-color: #EEEEEE;
    color: #2C2C2C;
}

.cheque-reg-select{
    min-height: 1.9rem !important;
    background: #f0f2f4 !important;
}

.cheque-reg-select input{
    padding: 0.2rem 1rem !important;
    border: 1px solid #BEBEBE !important;
}

.cheque-reg-select .icon{
    padding: 0 !important;
    height: 1.9rem !important;
    margin-right: 0.1rem !important;
    display: flex;
    align-items: center;
    
}

.cheque-amt-box{
    background: #EEE;
    border-radius: 0.3125rem;
    border: 1px solid #BEBEBE;
    font-size: 1.25rem;
    
}

.cheque-exit{
    background-color:#6F6F6F ;
    color: #EEE;
}

.cheque-save{
    border-style: none;
}

.date-cheque-reg{
    border-radius: 0.3125rem;
    border: 1px solid #BEBEBE;
    background-color: #efeaea;
}

.date-cheque-reg-p{
    border-radius: 0.3125rem;
    border: 1px solid #9b9b9b;
    background-color: #EEEEEE;
    width: 15rem !important;
    height: 2rem;
}



.table-cheque-register-edit{
    height: 15rem !important;
    overflow: auto;
}

.table-cheque-register-edit th{
    position: sticky;
    top: 0;
    background: #000;
    
}
