.materials thead tr th{
    border: 2px solid #abc9ff;
    background-color: #abc9ff;
    color: black;
    position: sticky;
    top: 0px;
    z-index: 2;
    padding:  .08rem .08rem;
}
/* .materials thead tr th:first-child{
    width: 250px;
} */
.materials tbody tr input{
    border-radius: 5px;
    border: 1px solid #abc9ff;;
    padding-left: 20px;
}
.materials tbody tr{
    border: 1px solid#abc9ff;;
}
.materialsub tbody tr{
    border: 1px solid #624c6d;;
}

.materials tbody td{
    padding:  .05rem .05rem;
    background-color: white;
}
.TabHead {
    background-color: #abc9ff;
}

.materialsub thead tr th{
    border: 1px solid #624c6d;
    border-top:0;
    background-color: #624c6d;
    color: white;
    position: sticky;
    top: 0px;
    z-index: 2;
    padding:  .08rem .08rem;
}

.materialsub tbody tr{
   background-color: #f0f4f3;
   /* border:1px solid grey ; */
}

.materialsub tbody td{
    padding:  .05rem .05rem;
    background-color: white;
    border: white;
   /* border-left: 1px solid rgb(210, 210, 210);
   border-right: 1px solid rgb(210, 210, 210); */
}

.material-list thead tr th{
    border: 2px solid #abc9ff;
    background-color: #abc9ff;
    color: black;
    position: sticky;
    top: 0;
    z-index: 4;
}
.material-list tbody tr{
    background-color: #f0f4f3;
}
.material-list tbody tr :first-child{
    padding-left: 10px;
}

.material-list tbody tr :last-child{
    padding-right: 10px;
}
.material-list tbody td{
    padding-top: 2px;
    padding-bottom: 2px;
}
.material-list tbody div {
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
}

.form-control.material-input-text {
text-transform: uppercase;
height: .25rem;
background: #eee;
border-radius: 0.3125rem;
font-size: 0.875rem;
}

.form-control.material-input-text:focus {
outline: 1px solid black !important;
box-shadow: none !important;
border: none;
}
  
.purchase-input-text.table-drop.custom-dropdown-width{
    min-width: 100% !important; 
    /* max-width: 100% !important;      */
    border-color: white !important;
}

.purchase-input-text.table-drop.custom-dropdown-width input{
    text-align: start !important;
}

.custom-text{
    background-color: white !important;
}
