.gp-trial-bal-gp-total{
    /* border: black solid; */
    box-shadow: 0px 0px 5px black;



}
.gp-trial-bal-gp-total td{
    background-color: rgb(233, 175, 182);
  
}

.group-trial-bt-row td{
    position: sticky;
    bottom: 0;
    background-color: rgb(106, 60, 78);
}
/* tyle={{position:"sticky",zIndex:"100",bottom:"0"}} */

.group-trial-table-td td{
    background-color: rgb(250, 231, 246);
}