.report-table-cont{
    border-radius: 0.3125rem;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.reports-btn{
    width: 11.78rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.stock-ledger-search .item_seach_bar_cont{
    height: 1.5rem;
}
.stock-ledger-search .item_seach_bar_cont input{
    height: 1.4rem;
    width: 100%;
}

.stock-ledger thead th:first-child{
    padding-left: 1rem;
}

.stock-ledger-cont{
    position: relative;
    height: 29.2rem;
    overflow: hidden;
    overflow-y: scroll;
}

.stock-ledger thead th .grey_th{
    background: #788C92;
    height: inherit;
    padding: 1rem 0rem;
    color: white;
}

.stock-ledger thead th{
    z-index: 4;
    position: sticky !important;
    top: 0 !important;
}

.stock-ledger thead th .brn_th{
    background: #F48686;
    height: inherit;
    padding: 1rem 0rem;
    color: white;
}
.stock-ledger thead th .prple_th{
    background: #4A00A8;
    height: inherit;
    padding: 1rem 0rem;
    color: white;
}
.stock-ledger thead th .grn_th{
    background: rgba(153, 248, 129, 0.99);
    height: inherit;
    padding: 1rem 0rem;
    color: white;
}

.stock-ledger tbody tr td{
    padding: 0 !important;
    height: inherit;
}

.stock-ledger tbody .grey_td{
    background: rgba(132, 143, 146,0.2);
    height: 1.4rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0rem;
    color: black;
    font-weight: 500;
}
.stock-ledger tbody .prple_td{
    background: rgba(75, 0, 173,0.4);
    height: 1.4rem !important;
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
}
.stock-ledger tbody .grn_td{
    background: rgba(32, 158, 0,0.2);
    height: 1.4rem !important;
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
}
.stock-ledger tbody .brn_td{
    background: rgba(243, 136, 136,0.2);
    height: 1.4rem !important;
    padding: 1.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
}

.stock-ledger thead th div{
    padding: 1rem 0rem;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
}

.stock-ledger thead th{
    background: #000;
    font-size: 14px;
    font-weight: 300;
    padding: 0;
}


.stock-ledger thead th:nth-child(2) div{
    background: #000;
    color: white;
}
.stock-ledger thead th:nth-child(1) div{
    background: #000;
    color: white;
}

.stock-ledger tbody td{
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    padding: 1rem 0px;
    border-bottom: 1px solid #EEE;
}

.stock-ledger tbody td:first-child{
    padding-left:1rem !important;
    border-left: 1px solid #EEE;
}

.stock-ledger tbody td:last-child{
    border-right: 1px solid #EEE;
}

.stock-ledger thead th .margin-r{
    margin-right: 3px !important;
}
.stock-ledger tbody td .margin-r{
    margin-right: 3px !important;
}

.stock-ledger tbody tr td{
    padding: 0 !important;
}