.trial-bal-table-head td{
    background-color: black;
    color: white;
    position: sticky;
    top: 0px;
    text-align: left;
    
}


.trial-balance-data td{
    text-align: left;
}

.trial-table-head-btm-row td{
    position: sticky;
    bottom: 0px;

}
