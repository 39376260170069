.stock-jdetails-cont {
  box-shadow: 0px 0px 5px 3px rgb(231, 231, 231);
  font-weight: 700;
}

.stock-jdetails-cont .stock-entry {
  font-weight: 500;
  font-size: 0.975rem;
  color: #5b5b5b;
}

.text-small {
  font-size: 11px;
  font-weight: normal;
}

.stock-journal-table thead th {
  text-align: center;
  background: black;
  color: white;
  font-size: 14px;
  padding: 0.7rem 0;
  font-weight: 500;
}

.stock-journal-table tbody td input:focus {
  border: 1px solid black !important;
  outline: 0;
  box-shadow: none;
}
.stock-journal-table tbody td input {
  font-size: 13px !important;
  font-weight: 500;
}
.stock-journal-table tbody td {
  padding-top: 0;
  background: white;
  padding-bottom: 0;
  text-align: center;
  font-size: 11px !important;
}

.stock-journal-table tbody td:first-child {
  padding-top: 0;
}

.stock-journal-table tbody td:first-child .item-search-drop {
  padding: 0.4rem;
}

.stock-journal-table {
  position: relative !important;
  border: 1px solid #d8d8d8;
}

.stock-journal-table tbody tr:last-child td {
  padding: 0.3rem 0.5rem;
  background: #dedede;
}
.stock-journal-table thead th {
  z-index: 11;
  position: sticky;
  top: 0;
}

.stock-journal-table tbody tr:last-child td {
  position: sticky;
  bottom: 0;
  padding: 0.3rem 0.5rem !important;
}

.purchase-input-text.table-drop .dropdown.icon {
  display: flex !important;
  align-items: center;
  height: 100% !important;
  padding: 0 !important;
  padding-right: 0.5rem !important;
}

.purchase-input-text.table-drop {
  min-height: fit-content !important;
  background: transparent !important;
  border: 1px solid black !important;
}

.purchase-input-text.table-drop input.search {
  text-transform: uppercase;
  padding: 0.3rem !important;
  padding-left: 0.9rem !important;
}

.purchase-input-text.drop {
  height: 1.8rem;
  width: 100%;
  border-radius: 0.2325rem;
  border: 1px solid #a7a7a7;
}

/* .purchase-input-text.drop.table{
    height: 1.5rem;
} */

.stock-journal-entry td {
  vertical-align: middle;
  background: #a6cdec !important;
}

.stock-next-prev-btn,
.stock-next-prev-btn:hover {
  color: white;
  background: #4a00a8;
  padding: 0.2rem 0.625rem;
}

.btn-focus:focus {
  background: white !important;
  color: black !important;
  outline: 1px solid black !important;
  box-shadow: none !important;
  border: none !important;
}

.purchase-input-text.drop.shadows {
  box-shadow: 0px 0px 5px 1px rgb(184, 184, 184) !important;
  border: 1px solid #bebebe !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stock-jsave-btn,
.stock-jsave-btn:hover {
  background: black;
  padding: 0.1rem 3rem;
  color: white;
}

.stock-journal-table-cont {
  height: 30.7rem;
  overflow: hidden;
  overflow-y: scroll;
}

.stockJ-edit {
  background: #000;
  color: white;
}

.stockJ-edit-table {
  border: 0px
}

.stockJ-edit-table thead th {
  z-index: 4;
  position: sticky;
  top: 0;
  background: #a4a1a1;
  padding: 0.7rem 0;
}

.stockJ-edit-table tbody td {
  padding: 0.6rem 0;
}

.stockJ-edit-table-cont{
  border: 1px solid #c9c9c9;
  height: 30rem !important;
  overflow: hidden;
  overflow-y: scroll;
}

.stockJ-edit-table-cont.p-return tbody tr td{
  border:0px;
  border-top: 1px solid lightgray;
}

.stockJ-edit-table-cont.p-return tbody tr.first-child td{
  background-color: #B5C0D0;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.add-less-btn:focus {
  background: white !important;
  color: black !important;
  outline: 2px solid black !important;
  box-shadow: none !important;
  border: none !important;
}
.add-less-btn {
  border: 0;
  border-radius: 0.3rem;
  background: #000000;
  color: white;
  padding: 0.3rem 1.6rem !important;
  font-weight: 500;
  font-size: 14px;
}

.stockJadj-table {
  padding: 1.55rem !important;
}
