.top-btn1{
    width: 100%;
    background-color: #4A00A8;
    color: white;
    border-radius: 0.3125rem;
}
.top-btn2{
    width: 100%;
    background-color: black;
    color: white;   
    border-radius: 0.3125rem;
}
.top-btn1:hover{
    background-color: #4A00A8;
}
.top-btn1:active{
    background-color: #4A00A8;
}
.top-btn2:hover{
    background-color:black;
}

.cashbook-input{
    width: 16.0625rem;
    height: 2rem;
    border-radius: 0.3125rem;
    border: 1px solid #A7A7A7;
    background: #EEE;
}

.cash-table-head{
    border-radius: 0.375rem 0.375rem 0rem 0rem;
    color: white;
    padding: .8rem;
    margin: .01rem;
    background-color:black;
    position: sticky;
    top: 0;
    
    
}
.cashbook-col div{
    background-color: #4B4B4B;
}
.cashbook-col div:hover{
    background-color: #4B4B4B !important;
}
.cashbook-table-main{
    margin-top: -4px;
}

.cashbook-ser-img{
    position: absolute;
    
}
.cash-search-input{
    width: 15.8125rem;
    height: 2rem;
    color: #4E4E4E;
    outline: none;

}
.cash-search-input:focus{
    border: none;
}

.cashbook-table-thead-th th{
    text-align: start;
    background-color: black;
    color: white;
    position: sticky;
    top: 3.56rem;
   
}



.cash-table-top{
    width: 100%;

}
.cashbook-table-bottom{
    background-color: #BD93F3;
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 1rem;
    font-weight: 600;
    
}

.cash-table-top thead tr th{
    margin: 0;
}

#cashbook-tr{
    padding: 0;
}

.cashbook-table-row{
    border: solid #D8D8D8 2px;
    
}

.cashbook-table-row td{
    text-align: left;
}

.cashbook-table-row li{
    list-style-type: none;
    color: #207929;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
}


.cashbook-accordion-tr{
    width: 100%;
    display: block;
}

.cashbook-accordion-header{
    background-color: #BD93F3 !important; 
    box-shadow: 1px 3px 7px 0px rgba(0, 0, 0, 0.25);
    color: white;
    font-size: 1rem;
    font-weight: 600;
    z-index: 999;
}

Accordion button{
    background-color: #4A00A8 !important;
}

.cashbook-accordion-header .accordion-button:not(.collapsed) {
    background: #BD93F3  !important;
    color: white;
    border: solid 2px ;
}

.cashbook-accordion-header .accordion-button:not(.collapsed):focus{
    border-style: none !important;
    outline-style: none !important;
}

.cashbook-down-box{
    height: 1rem;
    width: 5rem;
    background-color: white;
    border-radius: 5px;
}
.cashbook-table-row  input{
    text-align: start !important;
}
.table-head-scroll{
    /* width: 100%; */
    margin: 0;
    padding: 0;
    height: 35rem;
    /* overflow: auto; */
    overflow-y: auto;
    overflow-x: hidden;
}

/* .Ctable-scroll{
    height: 28rem;
    overflow-y: scroll;
    overflow-x: hidden;
} */