.pur-p-btn{
background: #4A00A8; 
color: #FFF;
}

.pur-s-btn{
background: #80A4C0; 
color: #FFF;
}
.production-head{
    background-color: black;
    color: #FFF;
}
.production-table{
    width: 100%;
}

.production-table th{
    background-color: #271A37;
    color: #FFF;
    padding-top:3px;
    padding-bottom: 5px;
}
.production-table tbody td{
    padding-top: 5px;
    padding-bottom: 2px;
    background-color: #F0F4F3;
    border-bottom: 0.1px solid rgba(0, 0, 0, 0.329);

}
.production-table tfoot td{
    background-color: #D6EDFF;
    padding-bottom: 3px;
    padding-top: 3px;
}
.production-table-fl{
    padding-right: 50px;
}
.production-table-fr{
    
    padding-right: 40px;
}
.bg-clr{
    background-color: #271A37;
    color: #FFF;
}

.bg-clr-1{
    background-color: #51385D;
    color: #FFF;
}

.production-table-ls thead th{
    color: #FFF;
    padding-top:3px;
    padding-bottom: 5px;
    background-color: #51385D;
}
.production-table-ls th div{
    background-color: rgba(255, 255, 255, 0.10);
}

.production-table-ls{
    width: 100%;
}

.production-table-ls td{
    background-color: #F0F4F3;
    padding-bottom: 3px;
    padding-top: 3px;
    text-align: center;
}
.production-table-ls tbody tr div{
    border-right: 1px solid black;
}

.pro-table-scroller{
    height: 29rem;
    overflow-y: scroll;
    overflow-x: hidden;
}