.prev_btn {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: black;
    border: 0;
    width: 4rem;
  }
  
  .white-to-black{
      -webkit-filter: invert(100%);
      filter: invert(100%);
  }
  
  .header {
    z-index: 1000;
    height: 3.5rem;
    top: 0;
    position: sticky;
  }
  
  .header-cont {
    background: white;
    color: black;
    box-shadow: 2px 0px 5px 0.5px gray;
  }
  
  /* .search_bar {
    outline: none;
    position: relative;
    background: #4e4e4e;
    width: 10rem;
    padding-left: 1rem !important;
    padding-bottom: 0.3rem !important;
    color: white;
  }
  
  .seach_bar_cont {
    display: flex;
    align-items: center;
    background-color: #4e4e4e;
    padding: 0.1rem 0.4rem;
  } */
  
  @media print {
    #new {
      border: 1px solid black !important; /* Important for overriding defaults */
    }
  }
  