.staff-table-cont{
    /* width:85rem !important; */
    height: 25.8rem;
    overflow: hidden !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 10px;
    min-width: 100%;
    /* max-width: 100%; */
    height: 24.8rem;
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 0;
}

.StaffTable thead th:first-child{
    color: black;
    width:25rem !important;
    height: 2rem !important;
    width:25rem !important;
    height: 2rem !important;
    position: sticky;
    left:0;
    z-index: 2;
}

.StaffTable tbody td:first-child{
    position: sticky;
    left:0;
    z-index: 1;
    background-color: #83aef9  ;
    color:rgb(0, 0, 0) !important;
}

.StaffTable thead tr th {
    background-color: #023c96   ;
    width: 3.5rem;
    height: 2rem;
    border: 1px solid rgb(0, 0, 0);
    color:white !important;
}

.StaffTable tbody tr td {
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid black;
    padding-left: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    text-align: center;
}

.StaffTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: .25rem;
}

.StaffTable thead tr th{
    position: sticky;
    z-index: 4;
    top: 0;
}

.staffModal{
    width: 30rem;
    height: fit-content;
}

.staffModal2{
    width: 30rem;
    height: fit-content;
}
.staffModal2{
    width: 30rem;
    height: fit-content;
}
