.acc-journal-details{
    background: white;
}

.acc-journal-table-cont{
    margin-bottom: 0;
    height: 26.5rem;
    overflow: hidden;
    overflow-y: scroll;
}

/* .acc-journal.table {
    box-shadow: 0px 0px 15.83738px 0px rgba(0, 0, 0, 0.20);
} */

.acc-journal.table thead th{
    position: sticky;
    z-index: 3;
    top: 0;
}
.acc-journal.table tbody tr:last-child td{
    position: sticky;
    z-index: 4;
    bottom: 0;
}

.acc-journal-footer textarea:focus{
    outline: 1px solid black;
    border:none !important;
    box-shadow: 0 !important;
}

.acc-journal-footer textarea{
    border-radius: 0.3125rem;
    border: 1px solid #A7A7A7;
    background: #EEE;
}

.acc-journal.table thead th{
    vertical-align: middle;
    background: #000;
    color: white;
    text-align: center;
}

.acc-journal.table tbody td{
    text-align: center;
    font-size: .9625rem;
    font-weight: 500;
    padding: 0.4rem 0.4rem;
    border-bottom: 1px solid rgb(196, 196, 196);
}

.acc-journal.table tbody tr:last-child td{
    font-size: 12px;
    font-weight: normal;
    background: #DEDEDE;
}

.acc-jnl-input-tr.entry-part td{
    vertical-align: middle !important;
    background: #a6cdec;
}

.acc-jnl-input-tr td .item-search-drop .search{
    border: 1px solid black;
    background: transparent !important;
}

.acc-journal.table tbody .acc-jnl-input-tr td input{
    text-transform: uppercase;
    text-align: center;
    background: transparent !important;
    border-radius: 0.4rem;
    width: 100%;
    padding: 0.2rem;
    border: 0;
}
.acc-journal.table tbody td input:focus{
    outline:  1px solid black !important;
    border: 0;
    box-shadow: 0;
}

.acc-journal.table .acc-journal-input{
    border-radius: 0.3125rem;
    border: 1px solid #BEBEBE;
    background: #EEE;
    padding: 0.5rem 0;
}

.acc-jnl-input-tr td 

.acc-journal-search input{
    width: 100%;
    height: 100%;
    text-align: start !important;
    padding: 0rem !important;
    padding-left: 0.5rem !important;
}
.acc-journal-search{
    min-height: 1.7rem !important;
    width: 100%;
}
.acc-journal-search .icon.dropdown{
    margin-right: 0.1rem !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 !important;
}