.PayrollTable thead tr th{
    border:0;
    background-color: black;
    color: white;
    position: sticky;
    top: 0;
}

.PayrollTable tbody tr{
    border: 2px solid grey;
    
}

.PayrollTable tbody tr td {
  border:  0;
  padding: .7rem .7rem;
}

.PayrollTable tfoot tr td{
    border: 0;
    background-color: black;
    color: white;
    position: sticky;
    bottom: 0;
}

.PayrollTable tbody input{
 border: 0 solid black;
 max-width:100px;
 text-align: center;
}

.rotate-in{
    transform: rotate(90deg);
}

.PayrollTable tr{
    height: .5rem;
} 

.PayrollTable thead tr th:first-child {
    width: 10rem;
}