.table-light.custom-table{
    --bs-table-color: #000;
    --bs-table-bg: #D9D9D9;
    --bs-table-border-color: #BEBEBE;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    border-radius: 0.3125rem 0.3125rem 0rem 0rem;
}


.custom-table th{
    text-align: center;
    height: 3.125rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #1A1A1A;
    vertical-align: middle;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.custom-table td{
    text-align: center;
    font-size: 0.875rem;
    height: 3.125rem;
    font-weight: 500;
    color: #4E4E4E;
    background: #FFF;
    vertical-align: middle;
}

.custom-table tr{
    border: 1px solid #EEE;
}

.btn-primary.add-btn{
    height: 2.188rem;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: 600;
    background: #4A00A8;
    border-radius: 0.5rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-scroller{
    height: 39rem;
    overflow-y: scroll;
    overflow-x: visible;
}

.button{
    cursor: pointer;
}

.itemList_header{
    position: sticky;
    top:3.5rem;
    z-index: 200;
}

.page_head{
    position: sticky;
    top: 3.5rem;
    background: #F5F5F5;
    padding-left: 1rem;
    z-index: 100;
}

.item_add{
    background-color: #f0f2f4;
}

.page_head_items{
    padding-top: 0.4rem;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    gap: 1.5rem;
}

.page_head_item ,.page_head_customer{
    cursor: pointer;
    padding-bottom: 0.3rem;
}

.page_head_item.active ,.page_head_customer.active{
    border-bottom: 0.2rem solid;
}

.pay-type-select{
    border: none;
    background: #000;
    border-radius: 0.3125rem;
    color: #FFF;
    margin-right: 0.1rem;
    height: 90%;
    width: 100%;
    padding-left: 0.75rem;  
}

.pay-type-select:focus{
    outline: 0.3px solid black;
}

.rates-btn{
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #88ED7F;    
}

.bottom-btn-section{
    display: flex;
    align-items: center;
    margin-top: 6.5rem !important;
}

.bottom-btn-section-2{
    display: flex;
    align-items: center;
    margin-top: 15rem !important;
}

.item_seach_bar_cont{
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0rem 0.9rem;
    border: 1px solid #D8D8D8
}

.item_search_bar{
    text-transform: uppercase;
    line-height: 0 !important;
    display: flex;
    align-items: center;
    outline: none;
    position: relative;
    background: white;
    width: 100%;
    padding-left: 1rem !important;
    color: #4E4E4E;
}

.item_add_cont{
    width: inherit;
    background-color: white;
    border-radius: 0.2rem;
    margin: 0 1.5rem 0 1.5rem ;
    padding: 0.5rem 1.5rem 1rem 1.5rem;
    font-weight: 600;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.item_input{
    text-transform: uppercase;
    height: 1.75rem;
    min-height: 1.75rem !important;
    background: white;
    border-radius: 0.3125rem;
    border: 1px solid #BEBEBE;
    font-size: 0.875rem;
    padding-left: 0.6rem;
    width: 100%;
}

.item_input:focus{
    outline: 0.3px solid black !important;
}

.item_input.no-outline:focus{
    outline: 0 !important;
}

.item_input.text-area{
    height: 100% !important;
}

.item_input.outline_none{
    outline: none;
}

.item_input_with_drop{
    /* overflow: hidden; */
    font-size: 1em;
    /* height: 1.5rem; */
    font-weight: 500;
    width: 100%;
    background:#f0f2f4;
    color: black;
    border: 1px solid #BEBEBE;
    border-radius: 0.3rem;
}

.select-field{
    text-transform: uppercase !important;
}

.item_add_first_row{
    display: flex;
    justify-content: space-between;
}

.item_inputs .names{
    width:16rem;
    /* margin-right: 1.7rem !important; */
}

.item_inputs{
    align-items: center;
    /* justify-content: space-between; */
}

.item_inputs.right{
    padding-right: 0rem !important;
    justify-content: space-between;
    padding-left: 1.7rem !important;
}

.item_add_form_part1{
    font-weight: 500;
    font-size: 11px;
    display: block;
    padding-right: 2rem !important;
    border-right: 1px solid #BEBEBE;
}
.item_add_form_part2{
    font-weight: 500;
    font-size: 11px;
    display: block;
}

.item_add_check{
    font-size: 12px;
    font-weight: 600;
    gap: 0.7rem;
}

.checkbox{
    display: flex;
    justify-content: space-between;
}

.clear_btn, .clear_btn:hover{
    border-radius: 0.3125rem;
    border: 1px solid #2C2C2C;
    font-size: 12px;
    padding: 0.5rem 2rem;
}
.save_btn, .save_btn:hover{
    border-radius: 0.3125rem;
    border: 1px solid #2C2C2C;
    background: #2C2C2C;
    color: white;
    font-size: 12px;
    padding: 0.5rem 2rem;
}

.unit_modal{
    width: 51rem;
    display: flex;
    align-items: center;
}

.unit_modal_body{
    background: #464646;
    border-radius: 5px; 
    /* height: 12rem; */
    overflow: hidden;
    overflow-y: scroll;
}

.table_cont{
    font-size: 12px;
}

.table-head{
    position: fixed;
    top: 0;
}
.table-head-input{
    /* position: fixed; */
    top:0;
}

.custom-table-2 th{
    width: 8rem;
    /* border: 1px solid white; */
    text-align: start;
    height: 2.125rem;
    font-size: 0.775rem;
    font-weight: 500;
    color: white;
    vertical-align: middle;
}

.custom-table-2 td{
    width: 8rem;
    text-align: start;
    font-size: 0.875rem;
    /* height: 2.125rem; */
    font-weight: 500;
    color: white;
    border: 1.3px solid #464646;
    background: #727272;
    vertical-align: middle;
}

.custom-table-2 td input{
    background: #727272;
    outline: none;
    border: none;
}

.custom-table-2 tr{
    border: none
}

.add_unit_btn, .add_unit_btn:hover{
    background: #4a00a8;
    color: white;
}

.btn-td{
    width: 5.5rem !important;
}

.unit_select{
    text-transform: uppercase;
    background: #727272;
    border: none;
}

.credit::placeholder{
    text-align: right;
    font-size: 8px;
}

.table-light.custom-table{
    --bs-table-color: #000;
    --bs-table-bg: #D9D9D9;
    --bs-table-border-color: #BEBEBE;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
    border-radius: 0.3125rem 0.3125rem 0rem 0rem;
}

.custom-table th{
    text-align: center;
    height: 3.125rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #1A1A1A;
    vertical-align: middle;
    padding: 0px;
   
}

.custom-table td{
    text-align: center;
    font-size: 0.875rem;
    height: 3.125rem;
    font-weight: 500;
    color: #4E4E4E;
    background: #FFF;
    vertical-align: middle;
}

.custom-table tr{
    border: 1px solid #EEE;
}

.btn-dark.filter-btn{
    height: 2.1875rem;
    background: #000;
    border-radius: 0.3125rem;
    border: 1px solid transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-primary.add-btn{
    height: 2.188rem;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: 600;
    background: #4A00A8;
    border-radius: 0.5rem;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button{
    cursor: pointer;
}

.customer-add-btn{
    width: 11rem;
}

.task-description-popover{
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 1rem;
    width: 16.9rem;
    overflow-y:hidden;
    overflow-x:hidden;
}

.task-desc-pop-container{
    width: 15.75rem;
    background: #F8F8F8;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
    color: #2C2C2C;
    font-size: 0.7425rem;
    line-height:1rem;
}
