.StaffTables thead tr th{
    border: 0;
    background-color: black;
    color: white;
    position: sticky;
    top: 0;
}

.StaffTables tbody tr{
    border: 2px solid grey;
}

.StaffTables tfoot tr td{
    border:0;
    background-color: black;
    color: white;
    position: sticky;
    bottom: 0;
}

.TabHead {
    background-color: #4a00a8;
    color:white
}

