.sidebar {
    /* display: flex;
      justify-content: center; */
    height: 100vh;
    width: 17rem;
    margin-right: 0 !important;
    background: #2c68d2;
    position: sticky;
    top: 0px;
    /* overflow: hidden;
      overflow-y: scroll; */
  }
  
  .header-item{
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.2rem;
      box-shadow: 0 0 5px 1px rgb(209, 209, 209);
  }
  
  .company-logo-cont {
    padding-top: 20px;
    z-index: 4;
    height: fit-content;
    top: 0px;
    display: flex;
    justify-content: center;
    position: sticky;
    width: 100%;
  }
  
  .company-logo {
    height: 2rem;
    width: 80%;
    /* background: #d9d9d9; */
  }
  
  .SidebarItems {
    overflow: hidden;
    height: 80%;
    overflow-y: scroll;
    display: flex;
    justify-content: start;
    flex-direction: column;
    justify-content: start;
    padding: 0 1rem !important;
  }
  
  .SidebarSpan {
    border-left: 1px solid white;
    color: white;
    width: fit-content;
  }
  
  /* .hr{
      height: 1.8rem;
      width: 1.4px;
      background-color: #468AEF;
  } */
  
  .SidebarItemText {
    cursor: pointer;
    padding-top: 0.4rem;
  }
  
  .SidebarItem {
    padding: 0.5rem 1.5rem;
    width: 100%;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.04rem;
    border-radius: 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    /* filter: brightness(0) invert(1); */
  }
  
  /* .SidebarItem:hover{
      filter: grayscale(0);
      background-color: white;
  } */
  
  .SidebarItem.active {
    color: black;
    background: #607be8;
  }
  
  .active .sidebar_icon {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }
  
  .heaader-user-cont{
      background: #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      gap: 0.4rem;
  }
  
  .header-user-prof-img{
      border-radius: 0.2rem;
      object-fit: cover;
      width: 2.5rem;
      height: 2.5rem;
      color: black;
  }
  
  .header-role-text{
      color: gray;
      font-weight: 400;
      
      font-size: 12px;
  }